import React from 'react';
import * as style from './Header.module.css';
import { DataContext } from '../DataProvider';

import FlightRow from '../FlightRow';

import styled from 'styled-components';

function Header() {
  const { flights, setApiWasRequested, setRequestWasHandled } =
    React.useContext(DataContext);

  // function handleClick() {
  //   console.log('handling click');
  //   setApiWasRequested(true);
  //   setRequestWasHandled(false);
  // }

  return (
    <>
      <MyHeader>
        <H1>Flights</H1>
      </MyHeader>
      <MaxWidthWrapper>
        <Wrapper>
          {flights.map((flight) => {
            console.log(flight);
            return <FlightRow data={flight} />;
          })}
        </Wrapper>
      </MaxWidthWrapper>
    </>
  );
}

export default Header;

const MyHeader = styled.header`
  width: min-content;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  padding-top: 50px;
  margin-bottom: 50px;
  background-color: var(--global-bg-color);
`;

const H1 = styled.h1`
  margin-top: 0px;
  /* background-color: yellow; */
  width: min-content;
  font-size: 24px;
  font-weight: 600;
  padding: 10px 80px;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-family: 'Inter', sans-serif;
  background-color: white;
`;

const MaxWidthWrapper = styled.div`
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border;
  padding-left: 16px;
  padding-right: 16px;
`;

const Wrapper = styled.div``;
