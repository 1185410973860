const awsmobile = {
    aws_project_region: 'us-east-1',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_V8EExMa40',
    aws_user_pools_web_client_id: '3vu14hs28u2tqp86u8uftd9hmq',
    oauth: {},
    aws_cognito_username_attributes: ['EMAIL'],
    aws_cognito_social_providers: [],
    aws_cognito_signup_attributes: ['EMAIL'],
    aws_cognito_verification_mechanisms: ['EMAIL'],
  };
  
  export default awsmobile;