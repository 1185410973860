import React from 'react';

import styled from 'styled-components';

import southwestIcon from '../../assets/southwest.png';
import americanIcon from '../../assets/american.png';
import jetBlueIcon from '../../assets/jetblue.png';
import allNipponIcon from '../../assets/allnippon.png';
import deltaIcon from '../../assets/delta.png';
import spiritIcon from '../../assets/spirit.png';
import frontierIcon from '../../assets/frontier.png';
import unitedIcon from '../../assets/united.png';

function FlightRow({ data }) {
  const {
    passenger,
    departure_date,
    arrival_time,
    flight_number,
    arrival_airport_code,
    departure_city,
    estimated_travel_time,
    departure_airport_code,
    arrival_date,
    legible_date,
    departure_time,
    arrival_city,
    airline,
  } = data;
  
  const formattedDepartureTime = departure_time.startsWith('0')
    ? departure_time.slice(1)
    : departure_time;
  const formattedArrivalTime = arrival_time.startsWith('0')
    ? arrival_time.slice(1)
    : arrival_time;
  const formattedTime = `${formattedDepartureTime} - ${formattedArrivalTime}`;
  const airlines = {
    southwest: 'Southwest',
    american: 'American',
    jetblue: 'Jet Blue',
    allnippon: 'ANA',
    delta: 'Delta',
    spirit: 'Spirit',
    frontier: 'Frontier',
    united: 'United',
  };

  const icons = {
    southwest: southwestIcon,
    american: americanIcon,
    jetblue: jetBlueIcon,
    allnippon: allNipponIcon,
    delta: deltaIcon,
    spirit: spiritIcon,
    frontier: frontierIcon,
    united: unitedIcon,
  };
  const icon = icons[airline];
  const displayAirline = airlines[airline];

  const toSvg = (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      xmlns='http://www.w3.org/2000/svg'
      stroke='#312D2A'
      style={{
        transform: 'rotate(270deg) translateX(-2.5px)',
        cursor: 'pointer',
        hover: { transform: 'scale(1.25)' },
        display: 'inline',
      }}
    >
      <path
        d='M4 6L8 10L12 6'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      ></path>
    </svg>
  );

  const iconContainer =
    airline === 'allnippon' ? (
      <AnaContainer>
        <img src={icon} alt={airline} />
      </AnaContainer>
    ) : (
      <IconContainer>
        <img src={icon} alt={airline} />
      </IconContainer>
    );

  return (
    <OuterContainer>
      <FlightContainer>
        {/* <div>Passenger: {passenger}</div> */}
        {/* <div>Departure Date: {departure_date}</div> */}
        <LeftHand>
          <OuterIconContainer>{iconContainer}</OuterIconContainer>

          <DateContainer>
            <MonthDay>{legible_date}</MonthDay>
          </DateContainer>
        </LeftHand>

        <TimeAirlineContainer>
          <Time>{formattedTime}</Time>
          <Airline>{displayAirline}</Airline>
        </TimeAirlineContainer>
        <DurationAirportContainer>
          <Time>{`${estimated_travel_time} • Nonstop`}</Time>
          <Airline>
            {departure_airport_code} {toSvg} {arrival_airport_code}
          </Airline>
        </DurationAirportContainer>

        <FlightNumber>{flight_number}</FlightNumber>
        {/* <div>{departure_city}</div> */}

        {/* <div>Arrival Date: {arrival_date}</div> */}
        {/* <div>Arrival City: {arrival_city}</div> */}
      </FlightContainer>
    </OuterContainer>
  );
}

const Time = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 2px;
  min-width: 180px;
`;

const FlightNumber = styled.div`
  font-size: 18px;
  font-weight: 600;
  min-width: 120px;
  margin-bottom: 2px;
`;

const Airline = styled.div`
  font-size: 14px;
  color: grey;
`;

const MonthDay = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 2px;
`;

const WeekDay = styled.div`
  font-size: 14px;
  color: grey;
`;

const DurationAirportContainer = styled.div`
  margin-left: 0px;
`;

const TimeAirlineContainer = styled.div`
  margin-left: 0px;
`;

const DateContainer = styled.div`
  margin-left: 10px;
`;

const OuterIconContainer = styled.div`
  min-width: 55px;
`;

const IconContainer = styled.div`
  max-width: 35px;
  max-height: 35px;
`;

const AnaContainer = styled.div`
  max-width: 55px;
  max-height: 35px;
  transform: translateY(9px) translateX(-5px);
`;

const LeftHand = styled.div`
  display: flex;
  min-width: 150px;
`;

const FlightContainer = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 18px 22px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  font-family: 'Inter', sans-serif;
`;

const OuterContainer = styled.div`
  margin-top: 5px;
  background-color: var(--global-bg-color);
  border-radius: 4px;
`;

export default FlightRow;
