import React from 'react';

// Note: replace "Data" with the name of the thing being provided.
// For usage, see the "DataContextUser" component.

export const DataContext = React.createContext();

const PUBLIC_ENDPOINT = process.env.REACT_APP_FLIGHTS_PUBLIC_API;

function DataProvider({ children }) {
  const randomItem = 'random item';
  const [flights, setFlights] = React.useState([]);
  const [apiWasRequested, setApiWasRequested] = React.useState(true);
  const [requestWasHandled, setRequestWasHandled] = React.useState(false);
  const token = 'BLANK';

  React.useEffect(() => {
    async function getFlights() {
      const URL = PUBLIC_ENDPOINT + `flight`;
      var HEADERS = {
        'Content-Type': 'application/json',
        Authorization: token,
      };
      if (token === 'BLANK') {
        HEADERS = {
          'Content-Type': 'application/json',
        };
      }
      const request = new Request(URL, {
        method: 'GET',
        headers: HEADERS,
        timeout: 100000,
      });
      const response = await fetch(request);
      const json = await response.json();
      console.log(json);
      setFlights(json);
      return json;
    }
    if (apiWasRequested && !requestWasHandled) {
      getFlights();
      setApiWasRequested(false);
      setRequestWasHandled(true);
    }
  }, [apiWasRequested, requestWasHandled, token]);

  // React.useEffect(() => {
  //   async function fetchData() {
  //     const request = new Request(PUBLIC_ENDPOINT, {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       timeout: 100000,
  //     });
  //     console.log(`API Was Requested!`);
  //     console.log(`${PUBLIC_ENDPOINT} `);

  //     const response = await fetch(request);
  //     const json = await response.json();
  //     console.log(json);
  //     return json;
  //   }
  //   console.log('data fetch requested');
  //   console.log(
  //     `apiWasRequested: ${apiWasRequested} && requestWasHandled: ${requestWasHandled}`
  //   );
  //   if (apiWasRequested && !requestWasHandled) {
  //     fetchData();
  //     setApiWasRequested(false);
  //     setRequestWasHandled(true);
  //   }
  // }, [apiWasRequested, requestWasHandled]);

  // function createItem(content, variant) {
  //   const nextItems = [
  //     ...items,
  //     {
  //       id: crypto.randomUUID(),
  //       content,
  //       variant,
  //     },
  //   ];

  //   setItems(nextItems);
  // }

  // function clearItem(id) {
  //   const nextItems = items.filter((item) => {
  //     return item.id !== id;
  //   });
  //   setItems(nextItems);
  // }

  return (
    <DataContext.Provider
      value={{
        flights,
        // randomItem,
        setApiWasRequested,
        setRequestWasHandled,
      }}
    >
      {children}
    </DataContext.Provider>
  );
}

export default DataProvider;
